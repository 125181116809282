<template>
  <div>
    <c-search-box @enter="getDataList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="LBLUSER"
            name="userId"
            v-model="searchParam.userId"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="특정 사용자 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getDataList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="LBLREMOVE"
                  icon="remove"
                  @beforeAction="deleteEnv"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :afterIcon="editable && dataeditable ? [
                    { name: 'search', click: true, callbackName: 'addUser', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeUser', color: 'red' }
                  ] : null"
                  :editable="editable && dataeditable"
                  :disabled="true"
                  :required="true"
                  label="LBLUSER"
                  name="userName"
                  v-model="data.userName"
                  @addUser="addUser"
                  @removeUser="removeUser">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :afterIcon="editable && dataeditable ? [
                    { name: 'search', click: true, callbackName: 'selectMenu', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeMenu', color: 'red' }
                  ] : null"
                  :editable="editable && dataeditable"
                  :disabled="true"
                  :required="true"
                  label="메뉴명"
                  name="menuNm"
                  v-model="data.menuNm"
                  @selectMenu="() => { isMenuOpen = true }"
                  @removeMenu="removeMenu">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="true"
                  label="url"
                  name="url"
                  v-model="data.url">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <q-dialog
      ref="menuDialog"
      persistent
      v-model="isMenuOpen">
      <q-card class="menu-card">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
          <div class="text-h6">메뉴</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectMenu"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                {{menuNm}}
              </q-chip>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="menu-content p-2">
          <q-tree
            ref="menuTree"
            :nodes="menuTree"
            node-key="sysMenuId"
            label-key="menuNm"
            children-key="children"
            no-nodes-label="메뉴가 없습니다."
            no-results-label=""
            :default-expand-all="false"
            selected-color="primary"
            :selected.sync="selectedMenu"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'push-specific-user',
  data() {
    return {
      searchParam: {
        userId: '',  // 사용자
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLUSER',
            align: 'center',
            sortable: true,
          },
          {
            name: 'menuNm',
            field: 'menuNm',
            label: '메뉴명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        sysFcmSpecificUserId: '',
        sysFcmTokenId: '',
        sysMenuId: '',
        userId: '',  // 사용자
        userName: '',
        url: '',
        menuNm: '',
      },
      isMenuOpen: false,
      menuNm: '메뉴를 선택하세요.',
      menuTree: [],
      selectedMenu: null,
      selectedSysMenu: {},
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.push.specific.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    selectedMenu() {
      let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);

      // this.menuCd = this.selectedMenu;
      this.menuNm = data.menuNm;
    },
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.push.specific.list.url;
      this.menuUrl = selectConfig.sys.menu.list.url
      this.detailUrl = selectConfig.sys.push.specific.get.url;
      this.insertUrl = transactionConfig.sys.push.specific.insert.url;
      this.updateUrl = transactionConfig.sys.push.specific.update.url;
      this.deleteUrl = transactionConfig.sys.push.specific.delete.url;
      this.getList();
      this.getMenuList();
    },
    getMenuList() {
      this.$http.url = this.menuUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deviceTypeCd: 'M',
      };
      this.$http.request((_result) => {
        this.menuTree = this.convertTree(_result.data);
      },);
      this.saveable = false;
      this.deleteable = false;
      this.selectedSysMenu = {};
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upMenuId',
        customID: 'sysMenuId',
      });
      return tree;
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDataList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.dataeditable = true;
      this.$http.url = this.$format(this.detailUrl, row.sysFcmSpecificUserId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        sysFcmSpecificUserId: '',
        sysFcmTokenId: '',
        sysMenuId: '',
        userId: '',  // 사용자
        userName: '',
        url: '',
        menuNm: '',
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        sysFcmSpecificUserId: '',
        sysFcmTokenId: '',
        sysMenuId: '',
        userId: '',  // 사용자
        userName: '',
        url: '',
        menuNm: '',
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteEnv() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.sysFcmSpecificUserId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ sysFcmSpecificUserId: result.data.sysFcmSpecificUserId })
      } else {
        this.getDataList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDataList();
    },
    selectMenu() {
      if (this.selectedMenu) {
        let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);
        this.data.sysMenuId = data.sysMenuId;
        this.data.menuNm = data.menuNm;
        this.data.url = data.url;
        this.$refs['menuDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '메뉴를 선택하세요.', // 메뉴를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeMenu() {
      this.data.sysMenuId = null;
      this.data.menuNm = null;
      this.data.url = null;
    },
    addUser() {
      this.popupOptions.title = '사용자 추가'; // 사용자  추가
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'./pushUserPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.userId = data[0].userId
        this.data.userName = data[0].userName
      }
    },
    removeUser() {
      this.data.userId = null;
      this.data.userName = null;
    },
  }
};
</script>
<style lang="sass">
.menu-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 500px !important
  overflow-y: hidden !important
.menu-content
  height: 100%
  max-height: 400px !important
  overflow-y: auto
.menu-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.custommenu
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .menuCloseIcon
    display: none
.custommenu:hover
  .menuCloseIcon
    display: block
.custommenu.q-field--dense .q-field__control, .custommenu.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.custommenu.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.custommenu.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>